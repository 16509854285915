
import { defineComponent, ref, computed, watch, PropType } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import PlusInput from '@/components/organisms/dms/common/PlusInput.vue'
import AppInput from '@/components/atoms/AppInput.vue'
import TextAlert from '@/components/molecules/TextAlert.vue'
import RadioButtonList from '@/components/molecules/RadioButtonList.vue'
import { Metadata, uploadFileData } from '@/data/dms/dmsData'

export default defineComponent({
  components: {
    PlusInput,
    AppInput,
    TextAlert,
    RadioButtonList,
  },
  props: {
    modelValue: {
      type: Array as PropType<Metadata[]>,
    },
    requiredCheck: {
      type: Boolean,
    },
  },
  setup (props, { emit }) {
    const router = useRouter()
    const store = useStore()
    const reuseFlag = store.getters.reuseFlag ? store.getters.reuseFlag : false
    const metadataList = computed({
      get: () => props.modelValue || [],
      set: (metadataList) =>
        emit('update:modelValue', metadataList),
    })
    const requiredFlag = computed({
      get: () => props.requiredCheck,
      set: (requiredFlag) =>
        emit('update:requiredCheck', requiredFlag),
    })
    const blurList = ref<string[]>([])

    watch(metadataList, () => {
      metadataList.value.sort((a, b) => a.sortNo - b.sortNo)
      if (!reuseFlag) {
        metadataList.value.forEach((element) => {
          if (element.id === '10008' && element.radioButton) {
            // ラジオボタンの場合に、提供制限の値に初期値を設定する
            element.value[0] = Array.from<string>(Object.values(element.radioButton)).find(rule => {
              return rule.startsWith('国立国会図書館内限定公開')
            }) || '国立国会図書館内限定公開'
          }
        })
      }
    })
    const isInvalidContentsUriNum = (contentsUriList: any) => {
      // 再利用の場合は次のファイルアップロードでチェックする
      if (reuseFlag) return false
      const uploadFileList: uploadFileData[] = store.getters.s3UploadFileList || []
      return uploadFileList?.length < contentsUriList?.length
    }

    const isNotSelected = (radioButtonList: any, selectedValue: any) => {
      // ラジオボタンリストのいずれかを選択しているかどうかをチェックする
      return !Object.values(radioButtonList).includes(selectedValue)
    }

    const isInvalidW3CDTFFormat = (value: any) => {
      if (!value) return false
      // W3CDTF 形式の入力かどうかをチェックする
      const regexpOfYYYY = new RegExp('^[0-9]{4}$')
      const regexpOfYYYYMM = new RegExp('^[0-9]{4}-(0[1-9]|1[0-2])$')
      const regexpOfYYYYMMDD = new RegExp('^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$')
      const regexpOfYYYYMMDDTHHMMTZD = new RegExp('^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])T([01][0-9]|2[0-3]):[0-5][0-9]\\+([01][0-9]|2[0-3]):[0-5][0-9]$')
      const regexpOfYYYYMMDDTHHMMSSTZD = new RegExp('^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])T([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]\\+([01][0-9]|2[0-3]):[0-5][0-9]$')
      const regexpOfYYYYMMDDTHHMMSSSTZD = new RegExp('^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])T([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]\\.[0-9]+\\+([01][0-9]|2[0-3]):[0-5][0-9]$')
      const regexpOfYYYYMMDDTHHMMSSZ = new RegExp('^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])T([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]Z$')
      return !value.match(regexpOfYYYY) &&
        !value.match(regexpOfYYYYMM) &&
        !value.match(regexpOfYYYYMMDD) &&
        !value.match(regexpOfYYYYMMDDTHHMMTZD) &&
        !value.match(regexpOfYYYYMMDDTHHMMSSTZD) &&
        !value.match(regexpOfYYYYMMDDTHHMMSSSTZD) &&
        !value.match(regexpOfYYYYMMDDTHHMMSSZ)
    }

    return {
      router,
      metadataList,
      blurList,
      requiredFlag,
      isInvalidContentsUriNum,
      isNotSelected,

      blur (id: string) {
        if (!blurList.value.includes(id)) {
          blurList.value = [
            ...blurList.value,
            id,
          ]
        }
      },
      convertRadioList (radioObj: any) {
        const radioList: any[] = []
        const array = Object.keys(radioObj)
        array.map((key: any) => {
          radioList.push({ value: radioObj[key], label: key })
        })
        return radioList
      },
      isInvalidW3CDTFFormat,
    }
  },
})
