
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import MetadataInput from '@/components/organisms/dms/OuterMetadataInput/MetadataInput.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import { Metadata, Contents, uploadFileData } from '@/data/dms/dmsData'
import { getDataCredential, postDataCredential } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'OuterMetadataInput',
  components: {
    DssIcon,
    AppButton,
    MetadataInput,
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const reacquireFlag = computed<boolean>(() => store.getters.reacquireFlag)
    const uploadFileList: uploadFileData[] = store.getters.s3UploadFileList || []
    const s3UploadFilePathList = uploadFileList.map(file => { return file.s3UploadFilePath })
    const nasDirName = ref(null)
    const metadataList = store.getters.contentsData ? ref(store.getters.contentsData.metadataOuters) : ref<Metadata[]>([])
    const getNewFlag = computed(() => metadataList.value === undefined)
    const requiredCheck = ref(false)
    const reuseFlag = store.getters.reuseFlag ? store.getters.reuseFlag : false
    const reusePid = store.getters.reusePid ? store.getters.reusePid : ''

    const sleep = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms))

    const getMetadata = async () => {
      if (reuseFlag) {
        const url = `${BASE_URL}/outer/item/reuse/metadata?pid=${reusePid}`
        const response = await getDataCredential(url)
        metadataList.value = response.data
      } else {
        setLoading(true)
        let url = `${BASE_URL}/outer/item/input/metadata/request`
        let body = JSON.stringify({
          s3UploadFilePathList: s3UploadFilePathList,
        })
        let response = await postDataCredential(url, body, false)

        if (response.status === 200) {
          url = `${BASE_URL}/outer/item/input/metadata/response`
          body = JSON.stringify({
            nasDirName: response.data,
          })
          for (let i = 1; i < 100; i++) {
            response = await postDataCredential(url, body, false)
            if (response.status === 200) break
            const ms = i < 20 ? 2000 : i < 50 ? 5000 : 10000
            await sleep(ms)
          }

          if (response.status === 200) {
            nasDirName.value = response.data.nasDirName
            if (response.data.daisyFileFlag || getNewFlag.value) {
              metadataList.value = response.data.metadataOuterList
            }
          }
        }
        setLoading(false)
      }
    }
    const setLoading = async (flag: boolean) => {
      await store.dispatch('setLoading', flag)
    }

    const setContentsData = async (contentsData: Contents) => {
      await store.dispatch('setContentsData', contentsData)
    }
    const setReacquireFlag = async (flag: boolean) => {
      await store.dispatch('setReacquireFlag', flag)
    }

    const packContentsData = async () => {
      setContentsData({
        metadataOuters: metadataList.value,
        s3UploadFilePathList: s3UploadFilePathList,
        nasDirName: nasDirName.value,
      })
    }
    onMounted(async () => {
      if (uploadFileList.length === 0 && !reuseFlag) {
        await router.push('/dms/outer/outputSelect')
      }
      if (reacquireFlag.value) {
        getMetadata()
      } else {
        setLoading(false)
      }
    })

    return {
      router,
      metadataList,
      requiredCheck,

      async next () {
        await window.fetch('')
        requiredCheck.value = true
        await window.fetch('')
        const elements = document.getElementsByClassName('text-alert')
        if (elements.length !== 0) {
          window.scrollTo({
            left: elements[0].getBoundingClientRect().left,
            top: elements[0].getBoundingClientRect().top,
            behavior: 'smooth',
          })
          return
        }
        await packContentsData()
        reuseFlag
          ? await router.push('/dms/outer/contentsInput')
          : await router.push('/dms/outer/inputConfirm')
      },
      async prev () {
        await window.fetch('')
        await packContentsData()
        await setReacquireFlag(false)
        reuseFlag
          ? await router.push('/dms/outer/select')
          : await router.push('/dms/outer/contentsInput')
      },
    }
  },
})
