
import { defineComponent, computed } from 'vue'
import AppInput from '@/components/atoms/AppInput.vue'

export default defineComponent({
  components: {
    AppInput,
  },
  props: {
    modelValue: {
      type: Array,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup (props, { emit }) {
    const inputValues = computed({
      get: () => props.modelValue,
      set: (inputValues) =>
        emit('update:modelValue', inputValues),
    })
    return {
      inputValues,
      props,

      plus () {
        const data = inputValues.value?.push('')
      },
      minus (num: number) {
        const data = inputValues.value?.splice(num, 1)
      },
    }
  },
})
