import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../../../assets/dms/plus-circle.svg'
import _imports_1 from '../../../../assets/dms/minus-circle.svg'


const _withScopeId = n => (_pushScopeId("data-v-611f0e3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick", "onKeydown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inputValues, (data, index) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "plusInput",
        key: index
      }, [
        _createVNode(_component_AppInput, {
          modelValue: _ctx.inputValues[index],
          "onUpdate:modelValue": ($event: any) => ((_ctx.inputValues[index]) = $event),
          label: _ctx.props.label,
          type: 'text',
          inputId: `${_ctx.label}-input-metadataPlus-${index + 1}`
        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "inputId"]),
        (index === 0)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.plus())),
              onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.plus()), ["prevent"]), ["space","enter"])),
              src: _imports_0,
              alt: "追加",
              class: "button-plus",
              tabindex: "0",
              role: "button"
            }, null, 32))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              onClick: ($event: any) => (_ctx.minus(index)),
              onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.minus(index)), ["prevent"]), ["space","enter"]),
              src: _imports_1,
              alt: "削除",
              class: "button-minus",
              tabindex: "0",
              role: "button"
            }, null, 40, _hoisted_1))
      ]))
    }), 128))
  ]))
}