
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import OuterMetadataInput from '@/components/organisms/dms/OuterMetadataInput/OuterMetadataInput.vue'
import Breadcrumb from '@/components/organisms/dms/common/Breadcrumb.vue'
import StopSend from '@/components/organisms/dms/common/StopSend.vue'
export default defineComponent({
  components: {
    OuterMetadataInput,
    Breadcrumb,
    StopSend,
  },
  setup () {
    const store = useStore()
    const reuseFlag = store.getters.reuseFlag ? store.getters.reuseFlag : false
    const list = [
      { text: 'ファイルをアップロード', numFlag: true },
      { text: 'メタデータを入力', numFlag: true },
      { text: '内容確認', numFlag: true },
      { text: '完了', numFlag: false },
    ]
    const reuseList = [
      { text: '再利用する資料を選択', numFlag: true },
      { text: 'メタデータを入力', numFlag: true },
      { text: 'ファイルをアップロード', numFlag: true },
      { text: '内容確認', numFlag: true },
      { text: '完了', numFlag: false },
    ]
    return {
      list,
      reuseList,
      reuseFlag,
    }
  },
})
